/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
// Icons
import LocationIcon from 'icons/map-marker.svg';
import LogoWhite from 'icons/logo-white.svg';
import FacebookIcon from 'icons/facebook.svg';
import LinkedInIcon from 'icons/linkedin.svg';
import InstagramIcon from 'icons/insta.svg';
import TwitterIcon from 'icons/twitter.svg';
import DottedBigIcon from 'icons/dotted-big.svg';
import { FOOTER, LINK } from 'src/formMapper';

const Footer = ({
  onClickProduct,
  onClickHowItWorks,
  onClickGetStarted,
  onClickAboutUs,
  onClickBankPartnerships,
  onClickTestimonials,
  onClickGetInTouch
}) => {
  return (
    <footer
      className='relative overflow-hidden bg-primary-dark'
      aria-labelledby='footerHeading '
      data-testid='footer-section'
    >
      <h2 id='footerHeading' className='sr-only'>
        Footer
      </h2>
      <div className='px-4 py-10 mx-auto lg:py-28 max-w-7xl sm:px-6 lg:px-8 lg:pb-16'>
        <div className='mb-8'>
          <LogoWhite />
        </div>
        <div className='lg:grid lg:grid-cols-12 lg:gap-20'>
          <div className='space-y-6 text-sm text-white md:pr-10 xl:col-span-5'>
            <p className='opacity-80'>{FOOTER.helpText.easiloan_description}</p>
            <div className='font-bold opacity-100 font-title'>{FOOTER.helpText.name}</div>
            <p className='relative -top-2 opacity-80'>{FOOTER.helpText.address}</p>
            <p className='relative -top-2 '>
              <a
                href='https://goo.gl/maps/AQT7eisjppMemYkJA'
                target='_blank'
                className='inline-flex items-center space-x-2.5'
                rel='noreferrer'
              >
                <LocationIcon />
                <span className='text-transparent bg-clip-text bg-gradient-to-b from-[#F08F45] to-[#E53F3E]'>
                  {FOOTER.helpText.view_in_map}
                </span>
                <span className='sr-only'>{FOOTER.helpText.location_icon_text}</span>
              </a>
            </p>
          </div>
          <div className='grid gap-12 mt-8 md:grid-cols-2 md:gap-20 lg:mt-12 xl:mt-0 xl:col-span-7'>
            <div className='grid grid-cols-2 gap-5'>
              <div>
                <ul className='space-y-6'>
                  <li>
                    <a
                      onClick={() => onClickProduct(0)}
                      className='hidden text-sm font-bold text-white uppercase cursor-pointer md:inline hover:opacity-70 font-title'
                      data-category='loan_product'
                      data-action='Products get started'
                      data-label='-'
                    >
                      {FOOTER.header.products}
                    </a>
                    <a
                      onClick={() => onClickProduct(50)}
                      className='text-sm font-bold text-white uppercase cursor-pointer md:hidden hover:opacity-70 font-title'
                      data-category='loan_product'
                      data-action='Products get started'
                      data-label='-'
                    >
                      {FOOTER.header.products}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => onClickHowItWorks(0)}
                      className='hidden text-sm font-bold text-white uppercase cursor-pointer md:inline hover:opacity-70 font-title'
                      data-category='process_flow'
                      data-action='How it works'
                      data-label='-'
                    >
                      {FOOTER.header.howItWorks}
                    </a>
                    <a
                      onClick={() => onClickHowItWorks(65)}
                      className='text-sm font-bold text-white uppercase cursor-pointer md:hidden hover:opacity-70 font-title'
                      data-category='process_flow'
                      data-action='How it works'
                      data-label='-'
                    >
                      {FOOTER.header.howItWorks}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => onClickGetStarted(0)}
                      className='hidden text-sm font-bold text-white uppercase cursor-pointer md:inline hover:opacity-70 font-title'
                      data-category='get_started'
                      data-action='Get Started'
                      data-label='-'
                    >
                      {FOOTER.header.getStarted}
                    </a>
                    <a
                      onClick={() => onClickGetStarted(65)}
                      className='text-sm font-bold text-white uppercase cursor-pointer md:hidden hover:opacity-70 font-title'
                      data-category='get_started'
                      data-action='Get Started'
                      data-label='-'
                    >
                      {FOOTER.header.getStarted}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => onClickAboutUs(0)}
                      className='hidden text-sm font-bold text-white uppercase cursor-pointer md:inline hover:opacity-70 font-title'
                      data-category='about_us'
                      data-action='About Us'
                      data-label='-'
                    >
                      {FOOTER.header.aboutUs}
                    </a>
                    <a
                      onClick={() => onClickAboutUs(70)}
                      className='text-sm font-bold text-white uppercase cursor-pointer md:hidden hover:opacity-70 font-title'
                      data-category='about_us'
                      data-action='About Us'
                      data-label='-'
                    >
                      {FOOTER.header.aboutUs}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => onClickBankPartnerships(0)}
                      className='hidden text-sm font-bold text-white uppercase cursor-pointer md:inline hover:opacity-70 font-title'
                      data-category='bank_partnership'
                      data-action='Bank Partnership'
                      data-label='-'
                    >
                      {FOOTER.header.partnership}
                    </a>
                    <a
                      onClick={() => onClickBankPartnerships(40)}
                      className='text-sm font-bold text-white uppercase cursor-pointer md:hidden hover:opacity-70 font-title'
                      data-category='bank_partnership'
                      data-action='Bank Partnership'
                      data-label='-'
                    >
                      {FOOTER.header.partnership}
                    </a>
                  </li>
                </ul>
              </div>
              <div className='mt-0'>
                <ul className='space-y-6'>
                  <li>
                    <a
                      onClick={() => onClickTestimonials(220)}
                      className='hidden text-sm font-bold text-white uppercase cursor-pointer md:inline hover:opacity-70 font-title'
                      data-category='testimonials'
                      data-action='Testimonials'
                      data-label='-'
                    >
                      {FOOTER.header.testimonials}
                    </a>
                    <a
                      onClick={() => onClickTestimonials(65)}
                      className='text-sm font-bold text-white uppercase cursor-pointer md:hidden hover:opacity-70 font-title'
                      data-category='testimonials'
                      data-action='Testimonials'
                      data-label='-'
                    >
                      {FOOTER.header.testimonials}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => onClickGetInTouch(0)}
                      className='hidden text-sm font-bold text-white uppercase cursor-pointer md:inline hover:opacity-70 font-title'
                      data-category='get_in_touch_footer'
                      data-action='Get In Touch'
                      data-label='/'
                    >
                      {FOOTER.header.getInTouch}
                    </a>
                    <a
                      onClick={() => onClickGetInTouch(20)}
                      className='text-sm font-bold text-white uppercase cursor-pointer md:hidden hover:opacity-70 font-title'
                      data-category='get_in_touch_footer'
                      data-action='Get In Touch'
                      data-label='/'
                    >
                      {FOOTER.header.getInTouch}
                    </a>
                  </li>
                  <li>
                    <Link href='/privacy-policy'>
                      <a
                        className='text-sm font-bold text-white uppercase hover:opacity-70 font-title'
                        target='_blank'
                        data-category='privacy_policy'
                        data-action='Privacy Policy'
                        data-label='-'
                      >
                        {FOOTER.header.privacyPolicy}
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href='/disclaimer'>
                      <a
                        className='text-sm font-bold text-white uppercase hover:opacity-70 font-title'
                        target='_blank'
                        data-category='disclaimer'
                        data-action='Disclaimer'
                        data-label='-'
                      >
                        {FOOTER.header.disclaimer}
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href='/terms-of-use'>
                      <a
                        className='text-sm font-bold text-white uppercase hover:opacity-70 font-title'
                        target='_blank'
                        data-category='terms_of_use'
                        data-action='Terms Of Use'
                        data-label='-'
                      >
                        {FOOTER.header.termsOfUse}
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='space-y-8 md:pl-8'>
              <div className='space-y-2'>
                <div className='text-sm font-bold text-white uppercase font-title'>{FOOTER.header.customerCare}</div>
                <div className='text-xl font-semibold text-secondary font-title'>
                  <a
                    href='mailto:info@easiloan.com'
                    className='text-transparent bg-clip-text bg-gradient-to-b from-[#F08F45] to-[#E53F3E]'
                    data-category='footer_email'
                    data-action='Mail via footer link'
                    data-label='-'
                  >
                    {FOOTER.header.emailAddress}
                  </a>
                </div>
              </div>
              <div className='space-y-2'>
                <div className='text-sm font-bold text-white uppercase font-title'>Stay Updated</div>
                <div className='flex pt-2 space-x-5'>
                  <a
                    href={LINK.fb_link}
                    target='_blank'
                    rel='noreferrer'
                    data-category='social'
                    data-action='Easiloan social'
                    data-label='-'
                  >
                    <span className='sr-only'>{FOOTER.helpText.facebook}</span>
                    <FacebookIcon />
                  </a>
                  <a
                    href={LINK.instagram_link}
                    target='_blank'
                    rel='noreferrer'
                    className='text-transparent bg-clip-text bg-gradient-to-b from-green-500 to-red-500'
                    data-category='social'
                    data-action='Easiloan social'
                    data-label='-'
                  >
                    <span className='sr-only'>{FOOTER.helpText.instagram}</span>
                    <InstagramIcon />
                  </a>
                  <a
                    href={LINK.linkedin_link}
                    target='_blank'
                    rel='noreferrer'
                    data-category='social'
                    data-action='Easiloan social'
                    data-label='-'
                  >
                    <span className='sr-only'>{FOOTER.helpText.twitter}</span>
                    <LinkedInIcon />
                  </a>
                  <a
                    href={LINK.twitter_link}
                    target='_blank'
                    rel='noreferrer'
                    data-category='social'
                    data-action='Easiloan social'
                    data-label='-'
                  >
                    <span className='sr-only'>{FOOTER.helpText.twitter}</span>
                    <TwitterIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='px-8 py-6 md:px-4 bg-primary-100 lg:py-6'>
        <p className='text-sm text-center text-white lg:text-sm'>{FOOTER.subHeader.copyright}</p>
        <p className='mt-2 text-center text-white text-xs11'>{FOOTER.helpText.termsAndCondition}</p>
      </div>
      <div className='absolute z-10 hidden opacity-50 right-16 -bottom-12 lg:block'>
        <DottedBigIcon />
      </div>
    </footer>
  );
};

Footer.propTypes = {
  onClickProduct: PropTypes.func.isRequired,
  onClickHowItWorks: PropTypes.func.isRequired,
  onClickGetStarted: PropTypes.func.isRequired,
  onClickAboutUs: PropTypes.func.isRequired,
  onClickBankPartnerships: PropTypes.func.isRequired,
  onClickTestimonials: PropTypes.func.isRequired,
  onClickGetInTouch: PropTypes.func.isRequired
};

export default Footer;
