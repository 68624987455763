import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { isLoggedIn } from 'src/helper';
import { useAuthState } from 'components/auth/hook';
import { useRouter } from 'next/router';
import { HEADER, PARTNER_LOGIN } from 'src/formMapper';

const Header = ({ isFixed }) => {
  const router = useRouter();
  const { user } = useAuthState();
  const loggedIn = isLoggedIn(user);
  const [isOpen, setIsOpen] = useState(false);
  const container = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (container.current) {
        if (container.current && !container.current.contains(event.target)) {
          if (!isOpen) return;
          setIsOpen(false);
        }
      }
    };
    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [isOpen, container]);

  useEffect(() => {
    const handleEscape = (event) => {
      if (!isOpen) return;
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    document.addEventListener('keyup', handleEscape);
    return () => document.removeEventListener('keyup', handleEscape);
  }, [isOpen]);

  const logOut = useCallback(() => {
    window.localStorage.removeItem('access');
    window.localStorage.removeItem('refresh');
    window.localStorage.removeItem('contactNo');
    window.localStorage.removeItem('loanType');
    window.localStorage.removeItem('loanData');
    window.location.href = '/login';
  }, []);

  return (
    <header
      className={`${isFixed && 'fixed inset-x-0 top-0 z-30'} pt-4 sm:pt-6 pb-3 bg-inherit ${
        router.pathname === '/' ||
        router.pathname === '/offers' ||
        router.pathname === '/home' ||
        router.pathname === '/congratulations'
          ? 'bg-floral'
          : 'bg-white'
      }`}
    >
      <div className='container flex items-center justify-between pl-4 pr-4 mx-auto'>
        <div className='flex-1 min-w-0 '>
          <Link href='/'>
            <a href='/' data-category='easiloan_icon' data-action='Home Page' data-label='-'>
              <img className='' src='/images/logo.svg' alt='Easy Loan Logo' />
            </a>
          </Link>
        </div>
        <div className='flex items-center space-x-0 sm:space-x-4'>
          {!loggedIn && (
            <>
              {router.pathname === '/' && (
                <>
                  <Link href='/partners/login'>
                    <a
                      href='/partners/login'
                      data-category='login_partner'
                      data-action='Login as Partner'
                      data-label='-'
                    >
                      <div className='text-xs text-primary sm:text-sm btn'>
                        <span>{PARTNER_LOGIN.header.partnerLogin}</span>
                      </div>
                    </a>
                  </Link>
                  <Link href='/login'>
                    <a
                      href='/login'
                      className='w-auto app-btn app-btn-primary'
                      data-category='login_customer'
                      data-action='Login as customer'
                      data-label='-'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        className='hidden sm:block'
                      >
                        <g fill='none' fillRule='evenodd'>
                          <g fill='currentColor' fillRule='nonzero'>
                            <path
                              d='M25 16c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9c0-2.387-.948-4.676-2.636-6.364C29.676 16.948 27.387 16 25 16zm0 2.7c1.491 0 2.7 1.209 2.7 2.7s-1.209 2.7-2.7 2.7-2.7-1.209-2.7-2.7 1.209-2.7 2.7-2.7zm5.237 10.05c-1.23 1.604-3.174 2.55-5.238 2.55-2.065 0-4.008-.946-5.239-2.55-.187-.267-.212-.611-.065-.902l.196-.397c.46-.946 1.445-1.55 2.526-1.551h5.164c1.066.001 2.038.59 2.507 1.515l.214.424c.15.293.126.641-.065.911z'
                              transform='translate(-16 -16)'
                            />
                          </g>
                        </g>
                      </svg>
                      <span>{HEADER.header.login_customer_text}</span>
                    </a>
                  </Link>
                </>
              )}
              {router.pathname === '/login' && (
                <div className='app-text-dm-small text-gray text-opacity-80'>
                  {HEADER.header.new_user_text}
                  <Link href='/signup'>
                    <a
                      href='/signup'
                      data-category='Customer Signup'
                      data-action='Click on New User Sign up'
                      data-label='/signup'
                    >
                      <span className='app-text-link text-secondary uppercase ml-[5px]'>
                        {' '}
                        {HEADER.header.sign_up_text}
                      </span>
                    </a>
                  </Link>
                </div>
              )}
              {router.pathname === '/signup' && (
                <div className='app-text-dm-small text-gray text-opacity-80'>
                  {HEADER.header.already_user_text}
                  <Link href='/login'>
                    <a href='/login'>
                      <span className='app-text-link text-secondary uppercase ml-[5px]'>
                        {' '}
                        {HEADER.header.login_here_text}
                      </span>
                    </a>
                  </Link>
                </div>
              )}
            </>
          )}
          {loggedIn && (
            <div ref={container} className='relative inline-block text-left'>
              <button
                type='button'
                onClick={logOut}
                className='w-auto transition-transform ease-out transform group hover:-translate-y-1 hover:shadow-btn-shadow app-btn app-btn-primary-outline'
                data-category='login_request_otp'
                data-action='Request OTP'
                data-label='-'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='13'
                  height='16'
                  viewBox='0 0 13 16'
                  className='mr-2 w-[10px]'
                >
                  <g fill='none' fillRule='evenodd'>
                    <g fill='currentColor' fillRule='nonzero'>
                      <path
                        d='M29.4 17h-8c-1.36 0-2.4 1.04-2.4 2.4v4.8h6.88l-1.84-1.84c-.32-.32-.32-.8 0-1.12.32-.32.8-.32 1.12 0l3.2 3.2c.32.32.32.8 0 1.12l-3.2 3.2c-.32.32-.8.32-1.12 0-.32-.32-.32-.8 0-1.12l1.84-1.84H19v4.8c0 1.36 1.04 2.4 2.4 2.4h8c1.36 0 2.4-1.04 2.4-2.4V19.4c0-1.36-1.04-2.4-2.4-2.4z'
                        transform='translate(-19 -17)'
                      />
                    </g>
                  </g>
                </svg>
                {HEADER.button.button_logout}
              </button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

Header.defaultProps = {
  isFixed: true
};

Header.propTypes = {
  isFixed: PropTypes.bool
};

export default Header;
